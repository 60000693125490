<template>
  <a-row class="content-fix" type="flex">
    <a-col class="height100 flex-1">
      <a-tabs class="main-tabs" type="card">
        <a-tab-pane key="content" class="main-tabs" type="card">
          <span slot="tab"> <a-icon type="project" />Содержимое </span>

          <!-- lang tabs -->
          <a-row align="middle" class="form_title sm-mb">
            <a-col :span="3" class="form_title"> Язык: </a-col>
            <a-col :span="12">
              <!-- lang translit tabs -->
              <a-row align="middle" class="flex-baseline flex-wrap">
                <lang-tabs v-model="langTab" class="mb-1x" />
              </a-row>
            </a-col>
          </a-row>

          <a-row align="middle" class="flex-baseline">
            <a-col :span="3" class="form_title"> Название: </a-col>
            <a-col :span="15" class="sm-mb">
              <a-input
                v-model="form['title_' + getKey]"
                name="linkName"
                class="form_title-input"
                placeholder="Введите название"
              />
            </a-col>
          </a-row>

          <!-- active toggle -->
          <a-row style="margin-bottom: 25px">
            <a-col :span="3" class="form_title"> Статус: </a-col>
            <a-switch v-model="form.is_active" />
          </a-row>

          <a-row style="margin-top: 20px">
            <a-col :span="3" />
            <a-col :span="12">
              <a-row>
                <a-button
                  style="margin-right: 10px"
                  @click="
                    $router.push({
                      name: 'opendata-docs'
                    })
                  "
                >
                  <a-icon type="close" />Отмена
                </a-button>

                <a-button
                  style="margin-left: 10px"
                  type="primary"
                  @click="
                    submit(form.id ? (saveback = false) : (saveback = true))
                  "
                >
                  <a-icon type="check" />
                  {{ form.id ? $t("Save") : $t("Add") }}
                </a-button>

                <a-button
                  v-if="form.id"
                  style="margin-left: 10px"
                  type="primary"
                  @click="submit((saveback = true))"
                >
                  <a-icon type="check" />
                  {{ $t("SaveAndExit") }}
                </a-button>
              </a-row>
            </a-col>
          </a-row>
        </a-tab-pane>

        <a-tab-pane key="documents">
          <span slot="tab"> <a-icon type="project" />Документы </span>
          <h4 v-if="pending">Идет загрузка...</h4>

          <span v-show="!pending">
            <a-row style="margin-bottom: 15px; margin-top: 15px">
              <a-col :span="3" class="form_title">Файлы документы:</a-col>

              <a-col :span="12">
                <a-button type="primary" @click="$refs.documentRef.click()">
                  <input
                    ref="documentRef"
                    accept=".xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf"
                    hidden
                    type="file"
                    multiple
                    @change="handleDocumentInput"
                  />
                  <a-icon type="upload" />Выберите файл
                </a-button>

                <template v-if="document_files_array.length !== 0">
                  <p style="padding-left: 15px">Файлы для загрузки</p>
                  <InputFileItem
                    v-for="item in document_files_array"
                    :key="item.uid"
                    :file="item.binary_file"
                    class="mt-2 no-a-hover"
                    @delete="deleteDocumentFileFromState(item)"
                  />

                  <a-row
                    style="border-bottom: 1px solid #ddd; padding-bottom: 15px"
                  />
                </template>
              </a-col>
            </a-row>
          </span>
        </a-tab-pane>
      </a-tabs>
    </a-col>
  </a-row>
</template>

<script>
import InputFileItem from "@/components/utils/input-file-item"
export default {
  components: { InputFileItem },
  data() {
    return {
      form: {
        title_uz: "",
        title_ru: "",
        title_en: "",
        title_oz: "",
        title_qr: "",
        budget_legally_open_information: null,
        is_active: false
      },
      pending: false,
      fileList: [],
      langTab: "oz",
      document_files_array: []
    }
  },
  computed: {
    getKey() {
      return this.langTab
    }
  },
  async mounted() {
    await this.fetchData()
  },
  methods: {
    async deleteDocumentFileFromState(arg) {
      // console.log(arg)
      if (this.form.id) {
        try {
          const res = await this.$api.delete(
            `/admin/budget/legally-open-information-file/${arg.binary_file.id}/delete/`
          )
          if (res) {
            const f = this.document_files_array.findIndex(
              (item) => item.uid === arg.uid
            )
            if (f !== -1) {
              this.document_files_array = [
                ...this.document_files_array.slice(0, f),
                ...this.document_files_array.slice(f + 1)
              ]
            }
          }
        } catch (err) {
          this.$sentry.captureMessage(err)
        }
      } else {
        this.document_files_array = this.document_files_array.filter(
          (el) => el.uid !== arg.uid
        )
      }
    },
    async handleDocumentInput(e) {
      if (this.form.id) {
        if (e.target.files && e.target.files !== 0) {
          let formImage = {
            budget_legally_open_information_attachment: this.$route.params.id
          }
          let f = new FormData()
          Object.keys(formImage).forEach((k) => {
            f.append(k, formImage[k])
          })
          // console.log(e.target.files)
          Array.from(e.target.files).forEach((item) => {
            f.append("file", item)
          })
          let res = await this.$api.post(
            "/admin/budget/legally-open-information-file/upload/",
            f
          )
          if (res && res.data) {
            this.$router.go(0)
          }
        }
      } else {
        if (e.target.files && e.target.files !== 0) {
          Array.from(e.target.files).forEach((item) => {
            this.document_files_array = [
              ...this.document_files_array,
              {
                binary_file: item,
                uid: Math.random().toString().slice(2)
              }
            ]
          })
          // console.log(this.document_files_array)
        }
      }
      this.$refs.documentRef.value = null
    },
    async fetchData() {
      if (!this.$route.params.id) return
      let id = this.$route.params.id
      const { data } = await this.$api.get(
        `/admin/budget/legally-open-information-attachment/${id}/detail/`
      )
      this.form = data
      this.form.files.forEach((item) => {
        this.document_files_array = [
          ...this.document_files_array,
          {
            binary_file: item,
            uid: Math.random().toString().slice(2)
          }
        ]
      })
    },
    async submit(saveback) {
      let f = new FormData()
      this.form = {
        ...this.form,
        budget_legally_open_information: this.$route.params.list_id
      }
      Object.keys(this.form).forEach((key) => {
        // console.log(key, this.form[key])
        key !== "file" && f.append(key, this.form[key])
        /*if (this.form[key]) {
          key !== "file" && f.append(key, this.form[key])
        }*/
      })
      this.document_files_array.forEach((item) => {
        item &&
          item.binary_file &&
          !item.binary_file.id &&
          f.append("files", item.binary_file, item.binary_file.name)
      })
      const createOrUpdate = {
        url:
          this.form && this.form.id
            ? "/admin/budget/legally-open-information-attachment/" +
              this.form.id +
              "/update/"
            : "/admin/budget/legally-open-information-attachment/create/",
        method: this.form && this.form.id ? "patch" : "post"
      }
      try {
        let res = await this.$api[createOrUpdate.method](createOrUpdate.url, f)
        if ((res && res.status === 201) || res.status === 200) {
          this.$message.success(
            `Успешно ${this.form.id ? "обновлена" : "создана"}!`
          )
        }
        if (saveback) {
          this.$router.push({
            name: "opendata-docs"
          })
        } else {
          this.fetchData()
        }
      } catch (e) {
        this.$message.error(
          `Ошибка при ${this.form.id ? "обновлении" : "создании"}!`
        )
        console.error(e)
      }
    }
  }
}
</script>

<style>
.committee-image {
  overflow: hidden;
  margin-top: 15px;
}
.committee-image > img {
  height: 200px;
}
.isInvalidUrl {
  border: 1px solid red;
  box-shadow: 0 0 3px red;
}
.error-message {
  color: red;
  font-size: 12px;
}
</style>
